// Libs
import React from 'react';
import { useSetRecoilState, useRecoilValue } from "recoil";

// Stylesheet
import './Dashboard.scss';

// Components
import SidePanel from "../../components/SidePanel/SidePanel";
import SidePanelItem from "../../components/SidePanel/SidePanelItem/SidePanelItem";
import Home from "./Home/Home";
import Certificates from "./Certificates/Certificates";
import Courses from "./Courses/Courses";
import Works from "./Works/Works";
import Experiences from "./Experiences/Experiences";
import ColorThemeSwitcher from "../../components/ColorThemeSwitcher/ColorThemeSwitcher";
import Modal from "../../components/Modal/Modal";

// Atoms
import { activePanelState } from "../../atoms/panel";
import { activeModalState, activeModalContent } from "../../atoms/modal";

export default function Dashboard() {
  const setActivePanel = useSetRecoilState(activePanelState);
  const activePanel = useRecoilValue(activePanelState);
  const setModalState = useSetRecoilState(activeModalState);
  const modalState = useRecoilValue(activeModalState);
  const modalContent = useRecoilValue(activeModalContent);
  const setModalContent = useSetRecoilState(activeModalContent);

  const componentMapping = {
    home: Home,
    certificates: Certificates,
    courses: Courses,
    works: Works,
    experiences: Experiences
  };
  const ActiveComponent = componentMapping[activePanel];

  function handleModalOpenerClick(content) {
    setModalContent(content);
    setModalState(true);
  }

  const closePopup = () => {
    setModalState(false);
  };

  return (
      <>
        <div className="global-container row">
          {modalState && (
              <Modal onClose={closePopup}>
                {modalContent}
              </Modal>
          )}
          <SidePanel>
            <SidePanelItem className={"active"} name="home" dataText="Accueil" icon="home" />
            <SidePanelItem name="certificates" dataText="Certificates" icon="certificates" />
            <SidePanelItem name="courses" dataText="Courses" icon="courses" />
            <SidePanelItem name="works" dataText="Works" icon="works" />
            <SidePanelItem name="experiences" dataText="Experiences" icon="experiences" />
          </SidePanel>
          <div className="admin-content">
            <ActiveComponent onClick={handleModalOpenerClick} />
          </div>
          <ColorThemeSwitcher />
        </div>
      </>
  );
}
