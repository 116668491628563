// Libs
import React from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil";

// Utils

// Atoms
import { activePanelState } from '../../../atoms/panel'

// Stylesheet
import './SidePanelItem.scss'

export default function SidePanelItem(props) {
    const { name, dataText, icon } = props;
    const setActiveAdminPanel = useSetRecoilState(activePanelState);
    const activeAdminPanel = useRecoilValue(activePanelState);

    const handleClick = async (e) => {
        console.log('test')
        toggleActive(e);
        togglePanel(e, name)
    }

    const toggleActive = (e) => {
        document.querySelectorAll('.side-panel-item.active').forEach(item => {
            item.classList.remove('active');
        });
        e.currentTarget.classList.toggle('active');
    }

    const togglePanel = (e, name) => {
        setActiveAdminPanel(name)
    }

    const className = props.className ? props.className : '';

    let iconCode;

    switch(icon) {
        case 'home':
            iconCode = <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512">
                <path className="fa-primary"
                      d="M266.9 7.9C279-2.6 297-2.6 309.1 7.9l256 224c13.3 11.6 14.6 31.9 3 45.2s-31.9 14.6-45.2 3L288 74.5 53.1 280.1c-13.3 11.6-33.5 10.3-45.2-3s-10.3-33.5 3-45.2l256-224z"/>
                <path className="fa-secondary"
                      d="M64 270.5L64.1 472c0 22.1 17.9 40 40 40H184c22.1 0 40-17.9 40-40V383.7c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32V472c0 22.1 17.9 40 40 40h80.5c22.1 0 40-18 40-40.1l-.4-201.3L288 74.5 64 270.5z"/>
            </svg>
            break;

        case 'certificates':
            iconCode = <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                <path className="fa-primary"
                      d="M512 160L352 0V128c0 17.7 14.3 32 32 32H512zm-402.8 1.6c-10-4.1-21.5-1-28.1 7.5L70.6 182.6c-1.3 1.7-3.2 2.7-5.2 3l-16.9 2.3c-10.7 1.5-19.1 9.9-20.5 20.5l-2.3 16.9c-.3 2.1-1.4 4-3 5.2L9.1 241.1c-8.5 6.6-11.6 18.1-7.5 28.1L8 285c.8 1.9 .8 4.1 0 6.1L1.6 306.8c-4.1 10-1 21.5 7.5 28.1l13.5 10.5c1.7 1.3 2.7 3.2 3 5.2l2.3 16.9c1.5 10.7 9.9 19.1 20.5 20.6L64 390.2V496c0 5.9 3.2 11.3 8.5 14.1s11.5 2.5 16.4-.8L128 483.2l39.1 26.1c4.9 3.3 11.2 3.6 16.4 .8s8.5-8.2 8.5-14.1V390.2l15.5-2.1c10.7-1.5 19.1-9.9 20.5-20.6l2.3-16.9c.3-2.1 1.4-4 3-5.2l13.5-10.5c8.5-6.6 11.6-18.1 7.5-28.1L248 291c-.8-1.9-.8-4.1 0-6.1l6.5-15.8c4.1-10 1-21.5-7.5-28.1l-13.5-10.5c-1.7-1.3-2.7-3.2-3-5.2l-2.3-16.9c-1.5-10.7-9.9-19.1-20.5-20.5l-16.9-2.3c-2.1-.3-4-1.4-5.2-3l-10.5-13.5c-6.6-8.5-18.1-11.6-28.1-7.5L131 168c-1.9 .8-4.1 .8-6.1 0l-15.8-6.5zM64 288a64 64 0 1 1 128 0A64 64 0 1 1 64 288z"/>
                <path className="fa-secondary"
                      d="M192 0c-35.3 0-64 28.7-64 64V168.6c1 0 2.1-.2 3-.6l15.8-6.5c10-4.1 21.5-1 28.1 7.5l10.5 13.5c1.3 1.7 3.2 2.7 5.2 3l16.9 2.3c10.7 1.5 19.1 9.9 20.5 20.5l2.3 16.9c.3 2.1 1.4 4 3 5.2l13.5 10.5c8.5 6.6 11.6 18.1 7.5 28.1L248 285c-.8 1.9-.8 4.1 0 6.1l6.5 15.8c4.1 10 1 21.5-7.5 28.1l-13.5 10.5c-1.7 1.3-2.7 3.2-3 5.2l-2.3 16.9c-1.5 10.7-9.9 19.1-20.5 20.6L192 390.2V496c0 5.9-3.2 11.3-8.5 14.1c-.7 .4-1.5 .7-2.3 1c3.5 .6 7.1 .9 10.7 .9H448c35.3 0 64-28.7 64-64V160H384c-17.7 0-32-14.3-32-32V0H192z"/>
            </svg>
            break;

        case 'courses':
            iconCode = <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                <path className="fa-primary"
                      d="M288 112.6l1.4 5.2L412.2 85 395.8 23.7c-4.5-17-22-27.1-38.9-22.6L295.5 17.6c-2.7 .7-5.2 1.8-7.5 3.1L288 96H160v32H288l0-15.4zm132.4 3.2L297.6 148.8l66 247.4 122.8-32.9-66-247.4zM388.2 488.3c4.5 17 22 27.1 38.9 22.6l61.4-16.5c16.9-4.6 27-22.1 22.5-39.1l-16.3-61.1L371.8 427l16.3 61.3zM0 96v32H128V96H0zM128 384H0v32H128V384zm160 0H160v32H288V384z"/>
                <path className="fa-secondary"
                      d="M0 32C0 14.3 14.3 0 32 0H96c17.7 0 32 14.3 32 32V96H0V32zm0 96H128V384H0V128zM0 416H128v64c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V416zM160 32c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32V96H160V32zm0 96H288V384H160V128zm0 288H288v64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V416zM289.4 117.8L412.2 85l8.2 30.9L297.6 148.8l-8.2-30.9zm74.2 278.3l122.8-32.9 8.2 30.9L371.8 427l-8.2-30.9z"/>
            </svg>
            break;

        case 'works':
            iconCode = <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                <path className="fa-primary"
                      d="M288 112.6l1.4 5.2L412.2 85 395.8 23.7c-4.5-17-22-27.1-38.9-22.6L295.5 17.6c-2.7 .7-5.2 1.8-7.5 3.1L288 96H160v32H288l0-15.4zm132.4 3.2L297.6 148.8l66 247.4 122.8-32.9-66-247.4zM388.2 488.3c4.5 17 22 27.1 38.9 22.6l61.4-16.5c16.9-4.6 27-22.1 22.5-39.1l-16.3-61.1L371.8 427l16.3 61.3zM0 96v32H128V96H0zM128 384H0v32H128V384zm160 0H160v32H288V384z"/>
                <path className="fa-secondary"
                      d="M0 32C0 14.3 14.3 0 32 0H96c17.7 0 32 14.3 32 32V96H0V32zm0 96H128V384H0V128zM0 416H128v64c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V416zM160 32c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32V96H160V32zm0 96H288V384H160V128zm0 288H288v64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V416zM289.4 117.8L412.2 85l8.2 30.9L297.6 148.8l-8.2-30.9zm74.2 278.3l122.8-32.9 8.2 30.9L371.8 427l-8.2-30.9z"/>
            </svg>
            break;

        case 'experiences':
            iconCode = <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                <path className="fa-primary"
                      d="M384 96h80c25.6 0 48 22.4 48 48V288H0V144c0-25.6 22.4-48 48-48h80V56 48c0-25.6 22.4-48 48-48h8H328h8c25.6 0 48 22.4 48 48v8V96zm-48 0V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8V96H336z"/>
                <path className="fa-secondary"
                      d="M192 288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288z"/>
            </svg>
            break;
    }
    return(
        <>
            <div className={"side-panel-item " + className} onClick={handleClick}>
                <div className="side-panel-item-icon">
                    {iconCode}
                </div>
                <div className="side-panel-item-name">
                    {dataText}
                </div>
            </div>
        </>
    )
}