// Libs
import { atom } from 'recoil'

export const activeModalState = atom({
    key: 'modalState',
    default: false
})

export const activeModalContent = atom({
    key: 'modalContent',
    default: null
})