// Libs
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

// Utils

// Stylesheet
import './Loading.scss'

export default function Loading() {
    return(
        <>
            <div className="loading-container">
                <CircularProgress color="inherit"/>
            </div>
        </>
    )
}