// Libs
import React from 'react'
import { useRecoilState, useRecoilValue } from "recoil";

// Stylesheet
import './ColorThemeSwitcher.scss'

// Hooks
import useColorTheme from '../../hooks/useColorTheme'


export default function ColorThemeSwitcher() {
  const { colorTheme, toggleColorMode } = useColorTheme();
  return (
    <div className="color-theme-switcher" onClick={toggleColorMode}>
        {
            colorTheme === 'light' ?
            (
                <svg className="color-theme-switcher-icon" id="moon" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path class="fa-primary"
                          d="M191.8 128C85.8 128 0 214.1 0 320.2S85.8 512.4 191.8 512.4c52 0 99.1-20.7 133.6-54.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-8.1 1.4-16.5 2.2-25.1 2.2c-80.7 0-146.2-65.6-146.2-146.6c0-54.8 30-102.6 74.4-127.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-5.4-.5-10.8-.7-16.3-.7z"/>
                    <path class="fa-secondary"
                          d="M284.8 99.2l11 38.6c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11zM387.2 252.8l16.6 58c1.6 5.5 6.6 9.2 12.2 9.2s10.7-3.8 12.2-9.2l16.6-58 58-16.6c5.5-1.6 9.2-6.6 9.2-12.2s-3.8-10.7-9.2-12.2l-58-16.6-16.6-58c-1.6-5.5-6.6-9.2-12.2-9.2s-10.7 3.8-12.2 9.2l-16.6 58-58 16.6c-5.5 1.6-9.2 6.6-9.2 12.2s3.8 10.7 9.2 12.2l58 16.6z"/>
                </svg>
            ) :
            (
                <svg className="color-theme-switcher-icon" id="sun" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                  <path className="fa-primary" d="M256 144a112 112 0 1 0 0 224 112 112 0 1 0 0-224z"/>
                  <path className="fa-secondary"
                        d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V88c0 13.3 10.7 24 24 24s24-10.7 24-24V24zm0 400c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3 10.7 24 24 24s24-10.7 24-24V424zM512 256c0-13.3-10.7-24-24-24H424c-13.3 0-24 10.7-24 24s10.7 24 24 24h64c13.3 0 24-10.7 24-24zM88 280c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H88zM437 75c-9.4-9.4-24.6-9.4-33.9 0l-45.3 45.3c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L437 108.9c9.4-9.4 9.4-24.6 0-33.9zM154.2 391.8c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L75 403.1c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l45.3-45.3zM437 437c9.4-9.4 9.4-24.6 0-33.9l-45.3-45.3c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L403.1 437c9.4 9.4 24.6 9.4 33.9 0zM120.2 154.2c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L108.9 75c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l45.3 45.3z"/>
                </svg>
            )
        }
    </div>
  )
}