// Libs
import React from 'react'

// Utils

// Stylesheet
import './SidePanel.scss'

export default function SidePanel({children}) {
    return(
        <>
            <div className="side-panel">
                {children}
            </div>
        </>
    )
}