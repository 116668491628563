// Libs
import React, { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Navigate, Outlet } from 'react-router-dom'

// Utils
import axios from '../../utils/axios'

// Atoms
import { isAuth } from '../../atoms/auth'
import Loading from "../Loading/Loading";

export default function PrivateRoute() {
    const setIsAuthenticated = useSetRecoilState(isAuth);
    const isAuthenticated = useRecoilValue(isAuth);
    const [isAuthCheckEnded, setIsAuthCheckEnded] = useState(false);

    useEffect(() => {
        async function checkAuthentication() {
            await axios.get('/auth/iamlogged')
                .catch((err) => {
                    return err;
                })
                .then((res) => {
                    if (res.status === 200) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                    setIsAuthCheckEnded(true);
                });
        }
        checkAuthentication();
    }, []);

    if(!isAuthCheckEnded) {
        return (
            <>
                <Loading/>
            </>
        );
    } else if (isAuthenticated) {
        return <Outlet />;
    } else {
        return <Navigate to='/login' />;
    }
}