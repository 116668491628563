// Libs
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Atoms

// Helpers
import { history } from './helpers/history'

// PrivateRoute
import PrivateRoute from './components/PrivateRoute/PrivateRoute'

// Pages
import Login from './pages/Login/Login'
import Dashboard from "./pages/Dashboard/Dashboard";
import Error404 from './pages/Errors/404/404'

export default function Routing() {
	return (
		<Router history={history}>
			<Routes>
				{/* Homepage */}
				<Route path='/login' element={<Login />} />
				{/* Dashboard */}
				<Route exact path="/" element={<PrivateRoute />}>
					<Route exact path="/dashboard" element={<Dashboard />} />
				</Route>
				{/*	404 */}
				<Route path='*' element={<Error404/>} />
			</Routes>
		</Router>
	)
}