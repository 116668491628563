// Libs
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

// Atoms
import { selectedColorTheme } from '../atoms/colorTheme';

const useColorTheme = () => {
  const [colorTheme, setColorTheme] = useState(null);
  const setSelectedColorTheme = useSetRecoilState(selectedColorTheme);

  const colors = {
    light: {
      background: '#f7f3f3',
      primary: '#111827',
      secondary: '#898989',
      tertiary: '#2563EB',
      quaternary: '#E3E8ED',
      lightText: '#17213566',
    },
    dark: {
      background: '#111827',
      primary: '#f7f3f3',
      secondary: '#ABADB2',
      tertiary: '#2563EB',
      quaternary: '#172135',
      lightText: '#E3E8ED66',
    },
  };

  const setColorMode = (mode) => {
    const selectedColors = colors[mode];

    Object.keys(selectedColors).forEach((key) => {
      const value = selectedColors[key];
      document.documentElement.style.setProperty(`--color-${key}`, value);
    });

    localStorage.setItem('colorTheme', mode);
    setColorTheme(mode);
    setSelectedColorTheme(mode);
  };

  const toggleColorMode = () => {
    const newColorTheme = colorTheme === 'light' ? 'dark' : 'light';
    setColorMode(newColorTheme);
    setSelectedColorTheme(newColorTheme);
  };

  useEffect(() => {
    const savedColorTheme = localStorage.getItem('colorTheme');
    const userPrefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    const initialColorTheme = savedColorTheme || (userPrefersDark ? 'dark' : 'light');
    setColorMode(initialColorTheme);
    setSelectedColorTheme(initialColorTheme);
    setColorTheme(initialColorTheme);
  }, []);

  return { colorTheme, toggleColorMode };
};

export default useColorTheme;
