// Libs
import React from 'react'

// Stylesheet
import './Form.scss'

export default function Form(props) {
  const { className, children, id, error } = props
  return (
      <>
          <form className={className ? className : null} id={id ? id : null}>
            {children}
          </form>
          {error ? (
              <div className="form-error-container">
                  <div className="form-error">
                      {`Error : ${error.message}`}
                  </div>
              </div>
            ) : null
          }
      </>
  )
}