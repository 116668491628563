// Libs
import { atom } from 'recoil'

export const isAuth = atom({
    key: 'isAuth',
    default: false
})

export const userToken = atom({
    key: 'userToken',
    default: ''
})