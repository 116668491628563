// Libs
import React from 'react'

// Stylesheet
import './Home.scss'

export default function Home() {
  return (
    <div>Home</div>
  )
}