// Libs
import React, { useState } from 'react'
import { Navigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

// Utils
import axios from '../../utils/axios'

// Stylesheet
import './Login.scss'

// Atoms
import { userToken } from "../../atoms/auth";

// Components
import Form from '../../components/Form/Form'
import PasswordInput from "../../components/Form/PasswordInput/PasswordInput"

export default function Login() {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [formError, setFormError] = useState(null)
	const [navigate, setNavigate] = useState(false)
	const setUserToken = useSetRecoilState(userToken)
	const submitForm = async (e) => {
		e.preventDefault()
		if (username.trim().length > 0 && password.trim().length > 0) {
			const formData = {
				username: username,
				password: password
			}
			await axios.post('/auth/login', formData).then((data) => {
				if (data.status === 200) {
					setUserToken(data.data.token)
					setNavigate(true)
				}
			}).catch((e) => {
				switch (e.response.status) {
					case 200: {
						setNavigate(true)
					}
						break;

					case 401: {
						setFormError({
							message: "Wrong password"
						})
					}
						break;

					case 404: {
						setFormError({
							message: "User not found"
						})
					}
						break;

					case 403: {
						setFormError({
							message: "This user isn't allowed to access this page"
						})
					}
						break;

					case 500: {
						setFormError({
							message: "Internal server error"
						})
					}
						break;

					default: {
						setFormError({
							message: "Unknown error occurred. Please try again."
						})
					}
				}
			})
		} else {
			setFormError({
				message: "You must provide all the required fields."
			})
		}
	}

	if(navigate) {
		return <Navigate to="/dashboard" />
	}

	return (
		<section className="login">
			<Form className="login-form" error={formError}>
				<div className="input-row">
					<label className="input-label">
						<span className="input-label-text required">Username</span>
						<input
							className="input"
							type="text"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)} />
					</label>
				</div>
				<div className="input-row">
					<PasswordInput
						label="Password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)} />
				</div>
				<div className="input-row">
					<button className="button primary-button submit-form-button" onClick={submitForm}>
						Login
					</button>
				</div>
			</Form>
		</section>
	)
}