// Libs
import React from 'react'

// Stylesheet
import './Works.scss'

export default function Works() {
  return (
    <div>Works</div>
  )
}