// Libs
import React from "react";

// Stylesheet
import "./Modal.scss";

export default function Modal(props) {
  const { children, className, course, onClose } = props;
    const handleClick = (e) => {
        let element = document.querySelector('.modal-overlay');
        if (e.target === element) {
            onClose()
        }
    };

  return (
      <div className={className ? `modal-overlay ${className}` : 'modal-overlay'} onClick={handleClick}>
        <div className="modal-content">
          {children}
        </div>
      </div>
  )
}