// Libs
import React, { useState, useEffect } from 'react'

export default function PasswordInput(props) {
  const { label, placeholder, value, onChange } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
      setPasswordVisibility(!passwordVisibility);
  }

  useEffect(() => {
        const passwordInput = document.querySelector('.input-password');
        const passwordVisibilityToggle = document.querySelector('.password-visibility-toggle');
        if (passwordVisibility) {
            passwordInput.type = 'text';
            passwordVisibilityToggle.classList.add('visible');
        } else {
            passwordInput.type = 'password';
            passwordVisibilityToggle.classList.remove('visible');
        }
  })

  return (
      <label className="input-label">
        <span className="input-label-password required">{label ? label : "Password"}</span>
        <div className="password-row row">
            <input className="input-password" type="password" placeholder={placeholder ? placeholder : "Password"} value={value} onChange={onChange}/>
            <div className="password-visibility-toggle" onClick={togglePasswordVisibility}>
                {passwordVisibility ?
                    <svg className="password-visibility-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path className="fa-primary"
                              d="M305.2 89.9L282 108.5c-6.9 5.5-17 4.4-22.5-2.5s-4.4-17 2.5-22.5l23.2-18.6C298.9 54 315.9 48 333.5 48c15.2 0 30.1 4.5 42.8 13l8.6 5.7c7.4 4.9 9.3 14.8 4.4 22.2s-14.8 9.3-22.2 4.4l-8.6-5.7c-7.4-5-16.2-7.6-25.1-7.6c-10.3 0-20.2 3.5-28.2 9.9zM416 224a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zm0 169.9V302.4c-20.3 20.7-48.7 33.6-80 33.6c-61.9 0-112-50.1-112-112s50.1-112 112-112s112 50.1 112 112V393.9c0 33.1 17.1 63.9 45.1 81.4l11.4 7.1c7.5 4.7 9.8 14.6 5.1 22s-14.6 9.8-22 5.1l-11.4-7.1C438.7 479.1 416 438.1 416 393.9zM112.4 192a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm176 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                        <path className="fa-secondary"
                              d="M427.2 446.3C381.9 487.2 321.8 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256c0 62.8-22.6 120.3-60.1 164.9c-2.5-8.6-3.9-17.7-3.9-26.9V224c0-61.9-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112c31.3 0 59.7-12.9 80-33.6v91.5c0 18.4 3.9 36.2 11.2 52.4zM176.4 192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM144 352c0 8.8 7.2 16 16 16c14.3 0 37.9 2.9 60.6 11.7c22.8 8.8 42.7 22.7 53.1 43.4c4 7.9 13.6 11.1 21.5 7.2s11.1-13.6 7.2-21.5c-15.3-30.5-43.3-48.6-70.1-59C205.3 339.5 177.7 336 160 336c-8.8 0-16 7.2-16 16zM305.2 89.9c8-6.4 18-9.9 28.2-9.9c8.9 0 17.6 2.6 25.1 7.6l8.6 5.7c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L376.3 61c-12.7-8.5-27.6-13-42.8-13c-17.5 0-34.5 6-48.2 16.9L262 83.5c-6.9 5.5-8 15.6-2.5 22.5s15.6 8 22.5 2.5l23.2-18.6z"/>
                    </svg> :
                    <svg className="password-visibility-icon" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 640 512">
                        <path className="fa-primary"
                              d="M232 208c8.8 0 16-7.2 16-16s-7.2-16-16-16H118.2c-7.1 0-10.7-8.6-5.7-13.7l28.8-28.8c7.8-7.8 7.8-20.5 0-28.3s-20.5-7.8-28.3 0L51.9 166.4l-.8 .8C14.7 204.4 15 264.1 51.9 301c17.9 17.9 42.1 27.9 67.3 27.9l64.8 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-14 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5h62c8.8 0 16-7.2 16-16s-7.2-16-16-16H169.6c-2.2 0-4-1.8-4-4s1.8-4 4-4l78.4 0c8.8 0 16-7.2 16-16s-7.2-16-16-16H169.6c-2.2 0-4-1.8-4-4s1.8-4 4-4l62.4 0zm182.7-19c-7.2-5.1-8.9-15.1-3.7-22.3s15.1-8.9 22.3-3.7l81.8 58.4c2.3 1.6 5.1 2.3 7.8 2c9.3-1.1 13.2-12.4 6.6-19l-14.8-14.8c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l45.1 45.1 .8 .8c36.4 37.2 36.1 96.9-.8 133.8c-17.9 17.9-42.1 27.9-67.3 27.9H456c-8.8 0-16-7.2-16-16s7.2-16 16-16h19.5c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5L408 328c-8.8 0-16-7.2-16-16s7.2-16 16-16h68c2.2 0 4-1.8 4-4s-1.8-4-4-4H392c-8.8 0-16-7.2-16-16s7.2-16 16-16l74.6 0c7.4 0 13.4-6 13.4-13.4c0-4.3-2.1-8.4-5.6-10.9L414.7 189z"/>
                        <path className="fa-secondary"
                              d="M545.5 134.7c-15.9-6.5-34.8-3.3-47.7 9.6c-7.3 7.3-11.5 16.6-12.6 26.2l-37.9-27.1c-10.9-7.8-24.3-9.3-36.1-5.4c-6.2-1.4-12.6-2.1-19.2-2.1c-48.6 0-88 39.4-88 88c0 40.3 27.1 74.3 64.1 84.7c-.1 1.1-.1 2.2-.1 3.3c0 22.1 17.9 40 40 40h8c0 .3 0 .6 0 .9c0 22.1 17.9 40 40 40h64.8c5.5 0 10.9-.4 16.3-1.1C491.8 464 411.5 512 320 512c-109.6 0-203-68.8-239.6-165.6c12.4 4.3 25.5 6.5 38.8 6.5l64.8 0c22.1 0 40-17.9 40-40c0-.3 0-.6 0-.9h8c22.1 0 40-17.9 40-40c0-2.6-.2-5.1-.7-7.5c10.1-7.3 16.7-19.1 16.7-32.5s-6.6-25.3-16.7-32.5c.5-2.4 .7-4.9 .7-7.5c0-22.1-17.9-40-40-40H156.8l1.5-1.5c17.2-17.2 17.2-45 0-62.2c-6-6-13.2-9.9-20.9-11.7C183.8 29.3 248.5 0 320 0c97.5 0 182.2 54.5 225.5 134.7zM384 400c0-26.5-28.7-48-64-48s-64 21.5-64 48s28.7 48 64 48s64-21.5 64-48zm32.4-176c0 6.1-2.3 11.8-6.1 16H374.5c-3.8-4.2-6.1-9.9-6.1-16c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
                    </svg>
                }
            </div>
        </div>
      </label>
  )
}