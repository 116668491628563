// Libs
import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Cookies from 'js-cookie';

// Utils
import axios from '../../../utils/axios';

// Atoms
import { activeModalState } from '../../../atoms/modal';

// Stylesheet
import './Courses.scss';

// Components
import Form from '../../../components/Form/Form';

export default function Courses(props) {
    const { onClick } = props;
    const [courses, setCourses] = useState([]);
    const [formError, setFormError] = useState(null);
    const setModalState = useSetRecoilState(activeModalState);

    const titleRef = useRef(null);
    const previewRef = useRef(null);
    const notionPageIDRef = useRef(null);

    useEffect(() => {
        axios
            .get('api/getCourses')
            .then((res) => {
                setCourses(res.data);
                setModalState(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();

        let formData = {
            title: titleRef.current.value,
            preview: previewRef.current.value,
            notionPageID: notionPageIDRef.current.value,
            token: Cookies.get('token'),
        }

        if(formData.title.trim().length > 0 && formData.preview.trim().length > 0 && formData.notionPageID.trim().length > 0) {
            axios.post('api/createCourse', formData).then((res) => {
                if (res.status === 200) {
                    axios.get('api/getCourses').then((res) => {
                        setCourses(res.data);
                    }).catch((err) => {});
                }
            }).catch((err) => {
                switch (err.response.status) {
                    case 403: {
                        setFormError({
                            message: 'Forbidden',
                        });
                    }
                        break;

                    case 404: {
                        setFormError({
                            message: 'Not found',
                        });
                    }
                        break;

                    case 401: {
                        setFormError({
                            message: 'Unauthorized',
                        });
                    }
                        break;

                    default: {
                        setFormError({
                            message: 'An error occurred',
                        });
                    }
                        break;
                }
            });
        } else {
            setFormError({
                message: 'You must provide all the required fields.',
            });
        }
    }

    const modalContent = (
        <>
            <div className="courses-popup-container">
                <Form className="create-course-form" error={formError}>
                    <div className="input-row">
                        <label className="input-label">
                            <span className="input-label-text required">Title</span>
                            <input
                                className="input"
                                type="text"
                                placeholder="Title"
                                ref={titleRef} />
                        </label>
                    </div>
                    <div className="input-row">
                        <label className="input-label">
                            <span className="input-label-text required">Preview</span>
                            <input
                                className="input"
                                type="text"
                                placeholder="Preview"
                                ref={previewRef} />
                        </label>
                    </div>
                    <div className="input-row">
                        <label className="input-label">
                            <span className="input-label-text required">Notion Page ID</span>
                            <input
                                className="input"
                                type="text"
                                placeholder="Description"
                                ref={notionPageIDRef} />
                        </label>
                    </div>
                    <button className="button primary-button submit-form-button" onClick={submitForm}>Create</button>
                </Form>
            </div>
        </>
    );

    function deleteCourse(id, token) {
        let formData = {
            id: id,
            token: token,
        };
        axios
            .delete('api/deleteCourse', {
                data: formData,
            })
            .then((res) => {
                axios
                    .get('api/getCourses')
                    .then((res) => {
                        setCourses(res.data);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <h2 className="panel-title">Courses</h2>
            <div className="courses-container">
                <div className="courses-list">
                    {courses.map((course) => (
                        <div key={course._id} className="course" id={course._id}>
                            <div className="course-preview">
                                <img src={course.preview} alt="course-preview" />
                            </div>
                            <div className="course-details row">
                                <div className="course-title light-text">{course.title}</div>
                                <div className="course-author light-text">{course.createdBy.username}</div>
                            </div>
                            <div className="course-actions-container">
                                <div className="course-actions-toggle">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                        <path
                                            className="fa-primary"
                                            d="M0 96a48 48 0 1 1 96 0A48 48 0 1 1 0 96zM0 256a48 48 0 1 1 96 0A48 48 0 1 1 0 256zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                                        />
                                        <path
                                            className="fa-secondary"
                                            d="M160 96a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm0 160a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 112a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                                        />
                                    </svg>
                                </div>
                                <div className="course-actions-list">
                                    <div className="course-action" id="delete" onClick={() => deleteCourse(course._id, Cookies.get('token'))}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path
                                                className="fa-primary"
                                                d="M163.8 0c-12.1 0-23.2 6.8-28.6 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8z"
                                            />
                                            <path
                                                className="fa-secondary"
                                                d="M416 96H32V448c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V96zM144 176V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courses-add-button" onClick={() => onClick(modalContent)}>
                    <svg className="course-add-button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            className="fa-primary"
                            d="M200 280v64c0 13.3 10.7 24 24 24s24-10.7 24-24V280h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V168c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H136c-13.3 0-24 10.7-24 24s10.7 24 24 24h64z"
                        />
                        <path
                            className="fa-secondary"
                            d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3-10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                        />
                    </svg>
                </div>
            </div>
        </>
    );
}
