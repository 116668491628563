import './404.scss'
import React from 'react'

export default function Error404() {
  return (
    <>
        <div className="error404 container">
            <h1>404</h1>
            <p>404 Error</p>
        </div>
    </>
  )
}