// Libs
import React from 'react'

// Stylesheet
import './Certificates.scss'

export default function Certificates() {
  return (
    <div>Certificates</div>
  )
}